import ReactAudioPlayer from 'react-audio-player';
import styles from './audioPlayerComponent.module.scss'
import { useState } from 'react';
import { PauseIcon, PlayIcon } from '../../../lib/PocketPuzzle/icons';

export default function AudioPlayerComponent() {
    const [isPlaying, setIsPlaying] = useState(false);
    return (
        <div className={styles.audioPlayerComponent}>
                <div className={styles.headerAudio}>
                <span>Escute nossa sugestão de <br /> trilha durante esta aventura.</span>
                </div>
				<button className={styles.controlButton} onClick={ ()=> setIsPlaying((prev) => !prev) }>
                    {isPlaying ? <PauseIcon className={styles.icon} /> : <PlayIcon className={styles.icon}/>}
                </button>
				{isPlaying &&
				<ReactAudioPlayer
					className={styles.audioPlayer}
					loop
					src="/PocketPuzzle/audio/mock.mp3"
					autoPlay
				/>
				}
			</div>
    )
}