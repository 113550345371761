import styles from './modalGuide.module.scss';

export default function ModalGuide( {onClose} ) {

    return (
        <div className={styles.modalGuide}>
            <div className={styles.modalContent}>
            <p>Este guia vai te auxiliar a desvendar o Segredo do Barba Negra no Pocket Puzzle! 
                A lista de botões que você vê são <b>dicas</b>. <br /></p>
            <p><b>As dicas estão na ordem em que os passos devem ser executados. 
                Conforme vocês vão acessando as dicas, as dicas seguintes vão sendo liberadas.</b> < br/></p>
                <p>Caso ainda sinta que as dicas não são suficientes, tente dar um tempo no jogo ou até pedir ajuda para pessoas de fora. 
                Se mesmo assim ainda não conseguir mande um e-mail para contato@chavemestra.net. <br /></p>
                <p>Bom jogo!</p>
            </div>
            <button className={styles.closeButton} onClick={onClose}>
                    FECHAR
                </button>
        </div>
    )
}