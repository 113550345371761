import React, { useState } from 'react';
import styles from './modalClue.module.scss';

export default function ModalClue({ clue, onClose, onConfirm }) {
	const [isConfirmed, setIsConfirmed] = useState(false);

	if (!clue) return null;

	function handleConfirm(){
		setIsConfirmed(true);
		onConfirm();
	}

	return ( 
		<>
		<div className={styles.backdrop}></div>
		<div className={styles.modal}>
				{!isConfirmed ? (
					<>
						<p>Atenção, spoilers. Você realmente quer abrir esta dica?</p>
						<div className={styles.buttonGroup}>
							<button onClick={() => handleConfirm()} className={styles.confirmButton}>
								SIM
							</button>
							<button onClick={onClose} className={styles.cancelButton}>
								NÃO
							</button>
						</div>
					</>
				) : (
					<>
						<div className={styles.modalClue}> 
						<p className={styles.headerTitle}>{clue.headerTitle}</p>
						<p className={styles.text}>{clue.text}</p>
						</div>
						<button onClick={onClose} className={styles.closeButton}>
							VOLTAR
						</button>
					</>
				)}
			</div>
			</>
	);	
}
