import React from 'react';
import styles from './guide.modules.scss';

export default function Guide() {
	return (
		<div>
			<h1>Manual</h1>
		</div>
	);
}
