export const clueList= [{
    id: 1,
    title: "I",
    headerTitle: "DICA 1: PRIMEIRO PUZZLE",
    text: "A caveira revela um segredo.",
},
{
    id: 2,
    title: "II",
    headerTitle: "DICA 2: ACHANDO AS CARTAS",
    text: "A chave da caveira abre uma gaveta.",
},
{
    id: 3,
    title: "III",
    headerTitle: <>DICA 3: PRIMEIRO PUZZLE <br />DE LÓGICA</>,
    text: "As pedras devem ser olhadas com atenção.",
},
{
    id: 4,
    title: "IV",
    headerTitle: "DICA 4: O LABIRINTO",
    text: "O prêmio do labirinto vira uma ferramenta.",
},
{
    id: 5,
    title: "V",
    headerTitle: "DICA 5: AS MOEDAS",
    text: "Cada moeda tem o seu lugar e deve ser apontada para o local certo.",
},
{
    id: 6,
    title: "VI",
    headerTitle: "DICA 6: A GAVETA",
    text: "A gaveta carrega um segredo.",
},
{
    id: 7,
    title: "VII",
    headerTitle: "DICA 7: A NAVEGAÇÃO",
    text: "O vento que vem de um lado, leva a embarcação para o outro.",
},
{
    id: 8,
    title: "VIII",
    headerTitle: "DICA 8: A HISTÓRIA",
    text: "Ao abrir a caixa, você deve deduzir qual é o grande segredo de Barba Negra.",
}]