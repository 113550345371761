export function PlayIcon(props) {
    return (
        <svg
            {...props}
            width="60" height="auto" viewBox="0 0 94 107" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M86.8762 40.8996C96.3746 46.5871 96.3746 60.413 86.8762 66.1004L22.0589 104.911C12.3327 110.735 -2.22197e-06 103.69 -2.22197e-06 92.3108V14.6891C-2.22197e-06 3.30957 12.3327 -3.73503 22.0589 2.08878L86.8762 40.8996Z" fill="#C28C17" />
        </svg>
    )
}

export function PauseIcon(props) {
    return (
        <svg
            {...props}
            width="60" height="auto" viewBox="0 0 94 107" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="27.8047" height="100" rx="6" fill="#C28C17" />
            <rect x="41.7051" width="27.8047" height="100" rx="6" fill="#C28C17" />
        </svg>
    )
}

