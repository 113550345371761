import { toast, Bounce } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

/**
 * Open toast
 * @param {string} type - types: 'success', 'error', 'info', 'warning'.
 * @param {string} message - type string (your message).
 */
export default function showToast(message, type){

    

    console.log("message", message)
    console.log("message", type)
    const options = {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        
      };

    switch (type) {
        case 'success':
            toast.success(message, options)
            break;
        case 'error':
            toast.error(message, options)
            break;    
        case 'info':
            toast.info(message, options)
            break;    
        case 'warning':
            toast.warning(message, options)
            break;    
        
        default:
            break;
    }
}