import React from "react";
import styles from "./header.module.scss";
import { LogoSVG } from "../../lib/Home/icons";

export default function Header({aboutUsRef}) {
    function goTo(test){
        switch(test) {
            case "aboutUs": 
                aboutUsRef.current?.scrollIntoView({ behavior: "smooth" });
                break
            default:
                break
        }
            
    }
    return (
        <header className={styles.header}>
            <div className={styles.logo}><LogoSVG  />
            </div>
        <nav className={styles.nav}>
            <button className={styles.button}>Home</button>
            <button className={styles.button}>Produtos</button>
            <button className={styles.button} onClick={() => goTo("aboutUs")}>Quem somos</button>
        </nav>
        </header>
    )
}