import styles from './home.module.scss';
import Header from "../../components/Header/header";
import { useRef } from 'react';

export default function Home () {
    const aboutUsRef = useRef();
    return(
        <div className={styles.home} style={{ backgroundImage:"url('/Home/bg.png')"}}>
             <Header aboutUsRef={aboutUsRef}/>    
        </div>
    )
}