import Routes from './routes/Routes'
import { ToastContainer } from 'react-toastify';
import './styles.css'
function App() {
  return (
    <div className="app">
          <Routes/>
          <ToastContainer />
    </div>
  );
}

export default App;
